import React from 'react';
import { connect } from 'react-redux';
import { branch, compose, lifecycle, renderNothing, withHandlers, withPropsOnChange, withState } from 'recompose';
import { Column, Grid } from '@packages/ui-kit/core/grid';
import { FavoriteButton } from '../../components/layout/application-header/components/favorite-button';
import { Footer } from '../../components/layout/page-footer';
import { Slot } from '../../components/slots';
import { TYPE_TO_ACTIVITY_KEY } from '../../helpers/content-interaction/constants';
import { withLoader } from '../../components/with-loader';
import { ProgressIndicator } from '../../components/progress-indicator';
import { sendContentStatus } from '../../store/reducers/content-interaction';
import { getStory } from '../../store/reducers/content';
import { withMetaTags } from '../../components/meta-tags';
import styles from '../../styles/templates/story-template.module.scss';
import { setDigitalCoachDelayLoading } from '../../store/reducers/digital-coach';
import { ConfigService } from '../../services/config-service';
import { withBarrier } from '../../pages/account/pages/barrier/barrier';
import { VISIBILITY_PAGE_TYPES } from '../../helpers/pages/constants';
import { TabsSection } from '../../components/tabs-section';
import { StorySlider } from './components/story-slider';
import { CustomStoryCarousel } from './components/custom-carousel/custom-story-carousel';

const CAROUSEL_VERSION = {
  slick: 'slick',
  custom: 'custom'
};

const configKey = 'STORIES';

const Component = React.memo(props => {
  const { sortedSteps, beforeChange, stepNumber, stepsTotal, storyKey, storySlug, reportStatus } = props;
  const description = 'Please, use left and right arrows to navigate between the slides!';
  const { carouselVersion = CAROUSEL_VERSION.slick } = ConfigService.get(configKey, {});

  return (
    <>
      <Slot name='top-navigation-right'>
        <FavoriteButton slug={storySlug} ownKey={storyKey} type={TYPE_TO_ACTIVITY_KEY.story} />
      </Slot>
      <TabsSection pageType={VISIBILITY_PAGE_TYPES.story} />
      <ProgressIndicator pageNumber={stepNumber} pagesTotal={stepsTotal} configKey={configKey} grid />
      <Grid className={styles.body}>
        {carouselVersion === CAROUSEL_VERSION.custom ? (
          <Column id='article'>
            <CustomStoryCarousel
              storySlug={storySlug}
              beforeChange={beforeChange}
              reportStatus={reportStatus}
              items={sortedSteps}
            />
          </Column>
        ) : (
          <Column id='article' tabIndex={0} role='slider' aria-label={description}>
            <StorySlider
              storyKey={storyKey}
              sortedSteps={sortedSteps}
              beforeChange={beforeChange}
              reportStatus={reportStatus}
            />
          </Column>
        )}
        <div className={styles['arrows-space']} />
      </Grid>
      <Footer pageType='story' />
    </>
  );
});

Component.displayName = 'StoryTemplateComponent';

const mapStateToProps = ({ content }, { match }) => {
  const story = content.stories[match.params.slug];

  return {
    story,
    storyKey: story?.key,
    storySlug: story?.slug,
    storyPages: story?.storyPages ?? [],
    loading: content.loadingStories
  };
};

const DEFAULT_STEP_NUMBER = 1;
const SEO_PAGE_DATA_NAME = 'stories';

export const StoryTemplate = compose(
  withBarrier,
  connect(mapStateToProps, { getStory, sendContentStatus, setDigitalCoachDelayLoading }),
  withPropsOnChange(['story'], ({ story }) => ({ seoName: story?.seo?.name ?? SEO_PAGE_DATA_NAME })),
  withState('stepNumber', 'setStep', DEFAULT_STEP_NUMBER),
  withPropsOnChange(['storyPages'], ({ storyPages }) => {
    const sortedSteps = storyPages.sort((a, b) => a.pageNumber - b.pageNumber);

    return {
      sortedSteps,
      stepsTotal: sortedSteps.length
    };
  }),
  withHandlers({
    reportStatus:
      ({ story: { key }, sendContentStatus }) =>
      status => {
        const options = {
          activityKey: TYPE_TO_ACTIVITY_KEY.story,
          activityStatusKey: status,
          data: { key }
        };
        return sendContentStatus(options);
      },
    resetStepNumber:
      ({ setStep }) =>
      () =>
        setStep(DEFAULT_STEP_NUMBER),
    getStoryBySlug:
      ({ getStory, match }) =>
      () =>
        getStory(match.params.slug)
  }),
  lifecycle({
    componentDidMount() {
      const { getStoryBySlug } = this.props;

      getStoryBySlug();
    },
    async componentDidUpdate(prevProps) {
      const { story, match, getStoryBySlug, reportStatus, resetStepNumber, setDigitalCoachDelayLoading } = this.props;

      if (prevProps.story !== story) {
        await reportStatus('seen');
        resetStepNumber();
        setDigitalCoachDelayLoading(false);
      }

      if (match.params.slug !== prevProps.match.params.slug) {
        getStoryBySlug();
      }
    }
  }),
  withLoader,
  branch(({ story }) => !story, renderNothing),
  withMetaTags,
  withHandlers({
    beforeChange:
      ({ setStep, sortedSteps, storyKey, sendContentStatus }) =>
      (oldIndex, newIndex) => {
        if (oldIndex === newIndex) return;

        setStep(newIndex + 1);

        const { pageNumber } = sortedSteps[oldIndex];
        const options = {
          activityKey: TYPE_TO_ACTIVITY_KEY.story,
          activityStatusKey: 'pageRead',
          data: { key: storyKey, storyPageNumber: pageNumber }
        };

        sendContentStatus(options);
      }
  })
)(Component);

StoryTemplate.displayName = 'StoryTemplate';
