import React from 'react';
import PropTypes from 'prop-types';
import styles from '../../../styles/components/navigation-bar.module.scss';
import { NavLink } from '../../link';
import { AffiliateIcon } from '../../icons/affiliate-icon';
import { withDisplayCondition } from '../../../helpers/hocs/with-display-condition';
import { getNewConfig } from './index';

const NavigationItemComponent = React.memo(({ icon, labelText, link, nativeTrigger, introducer }) => (
  <li key={labelText} className={styles.item}>
    <NavLink
      to={link}
      className={styles.link}
      activeClassName={styles.active}
      replace={(to, { pathname }) => pathname.includes(to)}
      onClick={getNewConfig(nativeTrigger, introducer)}
    >
      <AffiliateIcon name={icon} className={styles.icon} />
      <span className={styles.label}>{labelText}</span>
    </NavLink>
  </li>
));

NavigationItemComponent.displayName = 'NavigationItem';

NavigationItemComponent.propTypes = {
  icon: PropTypes.string.isRequired,
  labelText: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  nativeTrigger: PropTypes.string,
  introducer: PropTypes.string
};

const NavigationItem = withDisplayCondition(NavigationItemComponent);

export { NavigationItem };
