import React from 'react';
import { branch, compose, renderNothing, withHandlers, withProps, withPropsOnChange } from 'recompose';
import { withRouter } from 'react-router-dom';
import { sortByWeight } from '../../helpers/array';
import { ConfigService } from '../../services/config-service';
import { Column, Grid } from '../layout/grid';
import styles from '../../styles/components/tabs-section/tabs-section.module.scss';
import { withDisplayCondition } from '../../helpers/display-condition/hocs/with-display-condition';
import { TabsSectionComponent } from './component';

const withWrapper =
  Component =>
  ({ edge, grid, forwardRef, ...props }) =>
    (
      <Grid className={styles.wrapper} ref={forwardRef}>
        <Column flex justify='left' edge={edge}>
          <Component {...props} />
        </Column>
      </Grid>
    );

export const TabsSection = compose(
  branch(() => !ConfigService.get('DISPLAY_COMPONENTS.TABS_SECTION', false), renderNothing),
  withPropsOnChange(['pageType'], ({ pageType }) => {
    const isVisibleConfig = ConfigService.get('TABS_SECTION.isVisible', {});

    return {
      isVisible: isVisibleConfig[pageType]
    };
  }),
  branch(({ isVisible }) => !isVisible, renderNothing),
  withProps(() => ({
    ...ConfigService.get('TABS_SECTION', {})
  })),
  withDisplayCondition('TABS_SECTION'),
  withRouter,
  withHandlers({
    selectTab:
      ({ history }) =>
      ({ url }) =>
        history.push(url)
  }),
  withPropsOnChange(['tabs', 'location'], ({ tabs, location }) => {
    return {
      tabs: sortByWeight(tabs)
        .filter(tab => tab.isVisible)
        .map(tab => ({ ...tab, isActive: tab.url === location.pathname }))
    };
  }),
  branch(({ tabs }) => !tabs.length, renderNothing),
  withWrapper
)(TabsSectionComponent);
