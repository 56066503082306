import React from 'react';
import PropTypes from 'prop-types';
import { Slot } from '../../components/slots';
import { PurifiedHtmlContainer } from '../../components/layout/purified-html-container';
import { FavoriteButton } from '../../components/layout/application-header/components/favorite-button';
import { TYPE_TO_ACTIVITY_KEY } from '../../helpers/content-interaction/constants';
import styles from '../../styles/templates/video/video-template.module.scss';
import { Header } from '../../components/layout/page-header';
import { ConfigService } from '../../services/config-service';
import { AspectRatioContainer } from '../../components/layout/aspect-ratio-container';
import { Footer } from '../../components/layout/page-footer';
import { VISIBILITY_PAGE_TYPES } from '../../helpers/pages/constants';
import { TComponent, Template } from '../../components/template';
import { ASPECT_RATIOS } from '../../helpers/layout/constants';
import { Column, Grid } from '../../components/layout/grid';
import { TabsSection } from '../../components/tabs-section';
import { VideoWidgets } from './components/widgets';
import { Informers } from './components/informers';

// This wrapper component needs to handle the container for the video and handle if aspect ratio is not provided
const VideoContainer = ({ aspectRatio, children }) => {
  if (aspectRatio) {
    return <AspectRatioContainer aspectRatio={aspectRatio}>{children}</AspectRatioContainer>;
  }

  return <div className={styles['video-container']}>{children}</div>;
};

export const VideoTemplateComponent = React.memo(({ video }) => {
  const { isVisible, type } = ConfigService.get('VIDEOS.pageHeader', {});
  return (
    <>
      <Slot name='top-navigation-right'>
        <FavoriteButton slug={video.slug} ownKey={video.key} type={TYPE_TO_ACTIVITY_KEY.video} />
      </Slot>
      <TabsSection pageType={VISIBILITY_PAGE_TYPES.video} />
      {isVisible && (
        <Header type={type} heading={video.title} subheading={video.subTitle} identifier={video.identifierConfig} />
      )}
      <Template className={styles.body}>
        <TComponent edge>
          <VideoContainer aspectRatio={video.aspectRatio}>
            <PurifiedHtmlContainer
              parse
              html={video.url}
              className={styles.video}
              sanitizer={{
                ADD_TAGS: ['iframe'],
                ADD_ATTR: ['allow', 'height', 'width', 'allowfullscreen', 'frameborder']
              }}
            />
          </VideoContainer>
        </TComponent>
      </Template>
      <Grid>
        <Column className={styles.informers}>
          <Informers faqs={video.faqs} />
        </Column>
      </Grid>
      <VideoWidgets widgets={video.widgets} />
      <Footer pageType={VISIBILITY_PAGE_TYPES.video} />
    </>
  );
});

VideoTemplateComponent.displayName = 'VideoTemplateComponent';

VideoTemplateComponent.propTypes = {
  video: PropTypes.shape({
    slug: PropTypes.string,
    key: PropTypes.string,
    url: PropTypes.string,
    title: PropTypes.string,
    subTitle: PropTypes.string,
    identifierConfig: PropTypes.shape({
      text: PropTypes.string,
      styling: PropTypes.string
    }),
    widgets: PropTypes.shape({
      ...VideoWidgets.propTypes.widgets
    }),
    faqs: Informers.propTypes,
    aspectRatio: PropTypes.oneOf([Object.values(ASPECT_RATIOS)])
  })
};
