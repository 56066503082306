import React, { useMemo } from 'react';
import { compose } from 'recompose';
import styles from '../../../styles/components/multiple-input.module.scss';
import { InputGroup } from '../../../components/inputs/input-group';
import { withMemoAndRef } from '../../../helpers/hocs/with-memo-and-ref';
import { getInputStateIdentifier } from '../helpers/shemas-v2';
import { usePropPicker } from '../../../helpers/hooks/use-prop-picker';
import { withButton } from './components/input-field';
import { withPreSubmitButton } from './components/pre-submit-button/with-pre-submit-button';

const propsToPick = [
  'formErrors',
  'onError',
  'submitValue',
  'validationSchema',
  'setValidationSchema',
  'setFormValues',
  'setFormDisabledItems',
  'setFormErrorItems',
  'setSubmitAdditionalValues'
];

const Input = ({ items, children, ...props }) => {
  const { pickedProps } = usePropPicker(props, propsToPick);
  const sortedItems = useMemo(() => items.sort((a, b) => a.order - b.order), [items]);

  return (
    <InputGroup renderAs='div' heir className={styles.controller}>
      {sortedItems.map(({ type, data }) => {
        const { userAttributeType, userAttributeField } = data;

        return children({
          key: getInputStateIdentifier(userAttributeType, userAttributeField),
          type,
          data,
          ...pickedProps
        });
      })}
    </InputGroup>
  );
};

export const QuestionGroupInput = compose(withButton, withPreSubmitButton, withMemoAndRef)(Input);
