import Loadable from 'react-loadable';
import { TLoader } from '@apps/firsthomecoach/src/components/template';

const DigitalCoach = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "dashboard-digital-coach"*/ '@apps/firsthomecoach/src/pages/dashboard/widgets/digital-coach'
    ).then(module => module.DigitalCoach),
  loading: TLoader
});

const MyPlan = Loadable({
  loader: () =>
    import(/* webpackChunkName: "dashboard-my-plan"*/ '@apps/firsthomecoach/src/pages/dashboard/widgets/plan').then(
      module => module.MyPlan
    ),
  loading: TLoader
});

const DashboardAdverts = Loadable({
  loader: () =>
    import(/* webpackChunkName: "dashboard-adverts"*/ '@apps/firsthomecoach/src/pages/dashboard/widgets/adverts').then(
      module => module.DashboardAdverts
    ),
  loading: TLoader
});

const MarketPlaceWidget = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "dashboard-marketplace"*/ '@apps/firsthomecoach/src/pages/dashboard/widgets/marketplace'
    ).then(module => module.MarketPlaceWidget),
  loading: TLoader
});

const DashboardContentRepeater = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "dashboard-vm-content-repeater"*/ '@apps/firsthomecoach/src/widgets/repeater/content-repeater'
    ).then(module => module.DashboardContentRepeater),
  loading: TLoader
});

export const dashboardWidgets = {
  digitalCoach: DigitalCoach,
  myPlan: MyPlan,
  marketplace: MarketPlaceWidget,
  adverts: DashboardAdverts,
  repeater: DashboardContentRepeater
};
