import React from 'react';
import { useSelector } from 'react-redux';
import styles from '../../../../../styles/components/desktop-application-header.module.scss';
import { withDisplayCondition } from '../../../../../helpers/display-condition/hocs/with-display-condition';
import { getNewConfig } from '../../../navigation';
import { NavLink } from '../../../../link';

const Component = ({ labelText, link, nativeTrigger, isExternal, openNewTab }) => {
  const introducer = useSelector(state => state.account.introducer);

  return (
    <li key={labelText}>
      <NavLink
        to={link}
        replace={(to, { pathname }) => pathname.includes(to)}
        onClick={getNewConfig(nativeTrigger, introducer)}
        className={styles.link}
        activeClassName={styles.active}
        isExternal={isExternal}
        ariaLabel={labelText}
        openNewTab={openNewTab}
      >
        <span className={styles.label}>{labelText}</span>
      </NavLink>
    </li>
  );
};

export const DefaultItem = withDisplayCondition()(Component);
