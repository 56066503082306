export const lifehubPagesToPostFormat = posts => {
  return [
    {
      id: -1,
      category: 'LifeHub',
      slug: 'lifehub',
      subHeading: '',
      weight: 10,
      posts: posts.map(p => ({ ...p, title: p.name }))
    }
  ];
};
