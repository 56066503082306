import { ArrayShim } from '@packages/helpers/core/shims/array-shim';
import { createAction, createReducer } from '../utils';

const GET_PAGE_REQUEST = 'GET_PAGE_REQUEST';
const GET_PAGE_SUCCESS = 'GET_PAGE_SUCCESS';
const GET_PAGE_FAILURE = 'GET_PAGE_FAILURE';

export const getPageBySlug = params =>
  createAction(
    {
      request: GET_PAGE_REQUEST,
      success: GET_PAGE_SUCCESS,
      failure: GET_PAGE_FAILURE
    },
    api => api.pages.getPage
  )(params);

const GET_ARTICLE_PAGES_REQUEST = 'GET_ARTICLE_PAGES_REQUEST';
const GET_ARTICLE_PAGES_SUCCESS = 'GET_ARTICLE_PAGES_SUCCESS';
const GET_ARTICLE_PAGES_FAILURE = 'GET_ARTICLE_PAGES_FAILURE';

export const getArticlePages = params =>
  createAction(
    {
      request: GET_ARTICLE_PAGES_REQUEST,
      success: GET_ARTICLE_PAGES_SUCCESS,
      failure: GET_ARTICLE_PAGES_FAILURE
    },
    api => api.pages.getArticles
  )(params);

const initialState = {
  error: null,
  loading: true,
  content: {}
};

export const pages = createReducer(initialState, {
  [GET_PAGE_REQUEST]: state => ({ ...state, loading: true }),
  [GET_PAGE_SUCCESS]: (state, action) => ({
    ...state,
    content: {
      ...state.content,
      [action.payload.slug]: action.payload
    },
    loading: false
  }),
  [GET_PAGE_FAILURE]: state => ({ ...state, loading: false, error: true }),
  [GET_ARTICLE_PAGES_REQUEST]: state => ({ ...state, loading: true }),
  [GET_ARTICLE_PAGES_SUCCESS]: (state, action) => ({
    ...state,
    content: { ...state.content, ...ArrayShim.normalize(action.payload, 'slug') },
    loading: false
  }),
  [GET_ARTICLE_PAGES_FAILURE]: state => ({ ...state, loading: false, error: true })
});
