import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import { UserService } from './user-service';

export class BugsnagService {
  static isInitialized = false;

  static initialize() {
    try {
      if (!process.env.REACT_APP_BUGSNAG_API_KEY || process.env.REACT_APP_BUGSNAG_API_KEY === 'false') {
        // eslint-disable-next-line no-console
        console.error('[Bugsnag] API key is missing. Please provide a valid API key.');
        return;
      }

      Bugsnag.start({
        apiKey: process.env.REACT_APP_BUGSNAG_API_KEY,
        releaseStage: process.env.REACT_APP_RELEASE_STAGE,
        plugins: [new BugsnagPluginReact()],
        onError: event => {
          event.setUser(UserService.hasUser() ? 'Registered User' : 'Shadow User');
        }
      });

      BugsnagService.isInitialized = true;
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error('[Bugsnag] Failed to initialize', err);
    }
  }

  static notify(error) {
    if (BugsnagService.isInitialized) {
      try {
        Bugsnag.notify(new Error(error));
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log('[Bugsnag] Unable to notify', err);
      }
    }
  }
}
