import React from 'react';
import styles from '../../styles/components/tabs-section/tabs-section.module.scss';
import { TabItem } from './components/tab-item';

export const TabsSectionComponent = React.memo(({ tabs, selectTab }) => (
  <div className={styles.container}>
    {tabs.map(({ label, isActive, url, displayCondition }) => (
      <TabItem
        key={url}
        isActive={isActive}
        selectTab={selectTab}
        url={url}
        label={label}
        displayCondition={displayCondition}
      />
    ))}
  </div>
));
