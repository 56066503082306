import { compose, withHandlers, branch, renderNothing, defaultProps } from 'recompose';
import { connect } from 'react-redux';
import { evaluateStringCondition } from '../../checklist/display-condition';
import { omitProps } from '../../hocs/omit-props';
import { ConfigService } from '../../../services/config-service';
import { createConditionStateSelector } from '../create-condition-state-selector';

const mapStateToProps = (state, ownProps) => {
  const { configName, currentPageConfigName } = ownProps;
  const configurationName = currentPageConfigName || configName;
  const conditionVersion = ConfigService.get(`${configurationName}.conditionVersion`, null);

  return createConditionStateSelector(conditionVersion)(state);
};

export const withDisplayCondition = (configName = 'CHECKLIST') =>
  compose(
    defaultProps({ configName }),
    connect(mapStateToProps),
    withHandlers(() => ({
      isDisplayConditionMet:
        ({ userState, displayCondition }) =>
        () => {
          if (!displayCondition) return true;
          return evaluateStringCondition(displayCondition, userState);
        }
    })),
    branch(({ isDisplayConditionMet }) => !isDisplayConditionMet(), renderNothing),
    omitProps(['isDisplayConditionMet'])
  );
