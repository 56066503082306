import { ObjectShim } from '../shims/object-shim';

export const isUndefined = <T>(value: T): boolean => value === undefined && value === void 0;
export const isNil = <T>(value: T): boolean => isUndefined(value) || value === null;
export const isEqual = <T, V>(a: T, b: V): boolean => {
  if (ObjectShim.isObject(a) && ObjectShim.isObject(b)) {
    if (Object.keys(a).length !== Object.keys(b).length) {
      return false;
    }

    return Object.keys(a).every(key => isEqual(a[key], b[key]));
  }

  if (Array.isArray(a) && Array.isArray(b)) {
    if (a.length !== b.length) {
      return false;
    }

    return a.every((aValue, index) => isEqual(aValue, b[index]));
  }

  return Object.is(a, b);
};
