import { compose, withState, withHandlers, withProps, lifecycle } from 'recompose';
import { ObjectShim } from '@packages/helpers/core/shims/object-shim';
import { evaluateStringCondition } from '../../helpers/checklist/display-condition';
import { omitProps } from '../../helpers/hocs/omit-props';
import { withDisplayConditionState } from '../../helpers/display-condition/hocs/with-display-condition-state';
import { getNestedAttributeValue } from '../../pages/question/helpers/nested-attribute';

export const withInputRenderCondition = compose(
  withDisplayConditionState,
  withHandlers({
    updateItems:
      ({ submitValue, items, setFormValues, setFormDisabledItems, setFormErrorItems, userState, identifier }) =>
      () => {
        if (!items) {
          return [];
        }

        const updatedItemsPromises = items.map(item => {
          if (!item.data.displayCondition) {
            return item;
          }

          const updatedUserState = ObjectShim.mergeDeep(userState, submitValue);

          const conditionIsTrue = evaluateStringCondition(item.data.displayCondition, updatedUserState);

          if (!conditionIsTrue) {
            const itemPathPayload = {
              attributeType: item.data.userAttributeType,
              userAttributeType: item.data.userAttributeType,
              userAttributeField: item.data.userAttributeField
            };
            const existingValue = getNestedAttributeValue({ ...item.data, identifier }, userState);

            setFormDisabledItems(itemPathPayload)(false);
            setFormErrorItems(itemPathPayload)(null);
            setFormValues(itemPathPayload)(existingValue ?? null);
          }

          return conditionIsTrue && item;
        });

        return updatedItemsPromises.filter(Boolean);
      }
  }),
  withState('updatedItems', 'setUpdatedItems', ({ updateItems }) => updateItems()),
  lifecycle({
    componentDidMount() {
      const { setUpdatedItems, updateItems } = this.props;
      setUpdatedItems(updateItems());
    },
    componentDidUpdate(prevProps) {
      const { setUpdatedItems, updateItems, submitValue } = this.props;
      if (JSON.stringify(prevProps.submitValue) !== JSON.stringify(submitValue)) {
        setUpdatedItems(updateItems());
      }
    }
  }),
  withProps(({ updatedItems }) => ({ items: updatedItems })),
  omitProps('updatedItems')
);
