import React from 'react';
import { useSelector } from 'react-redux';
import styles from '../../../../../styles/components/desktop-application-header.module.scss';
import { withDisplayCondition } from '../../../../../helpers/display-condition/hocs/with-display-condition';
import { Column, Grid } from '../../../grid';
import { NavLink } from '../../../../link';
import { getNewConfig } from '../../../navigation';

const Component = ({ labelText, link, nativeTrigger, isExternal, openNewTab, toggle }) => {
  const introducer = useSelector(state => state.account.introducer);
  const onClick = nativeTrigger => () => {
    toggle(false);
    getNewConfig(nativeTrigger, introducer);
  };

  return (
    <Grid key={labelText} className={styles.item} renderAs='li'>
      <Column
        renderAs={NavLink}
        to={link}
        replace={(to, { pathname }) => pathname.includes(to)}
        onClick={onClick(nativeTrigger)}
        isExternal={isExternal}
        openNewTab={openNewTab}
        ariaLabel={labelText}
        activeClassName={styles.active}
      >
        <span className={styles.label}>{labelText}</span>
      </Column>
    </Grid>
  );
};

export const CollapseItem = withDisplayCondition()(Component);
