import React from 'react';
import { compose } from 'recompose';
import styles from '../../../styles/components/tabs-section/tabs-section.module.scss';
import { withDisplayCondition } from '../../../helpers/display-condition/hocs/with-display-condition';

const Component = ({ isActive, selectTab, url, label }) => (
  <div className={isActive ? styles['link-on'] : styles['link-off']} onClick={() => selectTab({ url })}>
    <p>{label}</p>
  </div>
);
export const TabItem = compose(withDisplayCondition('TABS_SECTION'))(Component);
