import { get } from '@apps/firsthomecoach/src/api/utils';

const getPage = ({ slug }) => get(`/content/pages/${slug}`);

const getPages = ({ tags }) => get(`/content/pages?tags=${tags}`);

const getArticles = () => getPages({ tags: 'article' });

export const pages = {
  getPage,
  getArticles
};
